
import { defineComponent, ref } from 'vue';
import store from '@/store';

export default defineComponent({
  setup() {
    const account = ref();
    const ancillaries = ref([]);
    const createAccount = () => ({});
    return {
      createAccount,
      organizationName: store.state.integration?.organization.name,
      account,
      ancillaries,
    };
  },
});
